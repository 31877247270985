import { find } from 'lodash'

import { ManagerDetailFragment } from "@docpace/shared-graphql/fragments"

interface HasManagerPermissionsIdInterface {
    departmentId: string
    providerId: string
}

export function hasManagerPermission(
    { departmentId, providerId }: HasManagerPermissionsIdInterface, 
    manager: ManagerDetailFragment
){
    if(!manager) return false
    const permission = find(manager?.managerProviderPermissions?.nodes, {
        departmentId: null,
        providerId
    }) ?? find(manager?.managerProviderPermissions?.nodes, {
        departmentId,
        providerId
    })
    return !!permission
}