export const practiceOverviewTooltipText = `Serves as the homepage for the tool, summarizing all the reported data for schedules fetched by DOCPACE. The tab helps identify areas that require attention and serves as a helpful monthly summary for internal distribution.` 
export const workflowQualityTooltipText = `Report on Encounter Workflows, Timestamps, Data Quality, and Open Encounters. 
- Workflow Compliance
- Open Encounters ` 
export const growthTooltipText = `Report on Schedule Outcomes, Clinic Growth, Patient Demographics, Payor Mix Breakdowns, Review Clinic Hours, Reschuled & Cancelled Visits, and Quickly Summarize the Prior Days Visits.
- Clinic Volumes 
- Patients
- Visits Completed
- Daily Clinic Review 
- Rescheduled Patients ` 
export const appointmentDurationsTooltipText = `Report of Appointment and Encounter State Durations and Trends, Calendar Views into these Durations, and Wait Time Analysis for Individual Days.
- Visit Duration
- Visit Duration Breakdown 
- Wait Time Comparison ` 
export const workingHoursTooltipText = `Report of completed visits, throughput rate, visit completion averages, provider cancelations, and provider schedules and working hours.
- Productivity
- Provider Hours ` 
export const patientBehaviorTooltipText = `
Report on Patient Behaviors and Schedule Outcomes that can have a negative impact on your Practice - Particularly No Shows, Cancellations, and Late Patients.
- No Shows
- Chronic No Shows 
- Prior Day No Shows
- Cancellations
- Patient Arrivals
- Late Visits 
- Adherence KPIs ` 
export const templateViewTooltipText = `View of schedule templates and builds, appointment durations, appointment density, slot utilization, and open slots.` 
export const checkoutTableTooltipText = `Summary of two most recent full-months with views into Patient Arrival, Appointment Durations, and Schedule Outcomes.`
export const patientFlowReportTooltipText = `Initial Schedule Audit performed by DOCPACE at the beginning of our Interface `
export const monthlyReportTooltipText = `Today's collected appointment data, real-time tracking of active appointments, and estimation of outcomes for future events.`