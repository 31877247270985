import {
    first,
    compact,
    toNumber,
} from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { Duration } from 'moment'

import { AppointmentDetailFragment, AppointmentOverviewFragment } from "@docpace/shared-graphql/fragments"
import { getEarliestTimestampInSet, getLatestTimestampInSet } from "@docpace/shared-ts-types"
import { AppointmentDurationsNumber, DelayTimestampsAppointment } from '@docpace/shared-ts-types'
import { getEarliestAppointmentEndTimeKey, getFirstAppointmentStartTimeKey, makeDurationFromStartEndTimes } from './appointmentStartEndUtils'

export function makeSecondsFromStartEndTimes(
    startTime: Moment | string | null | undefined,
    endTime: Moment | string | null | undefined,
    toNow = false
): number | null {
    endTime = endTime ?? (toNow ? moment() : null)
    if (!startTime || !endTime) return null
    if (!moment(startTime).isValid() || !moment(endTime).isValid()) return null
    const duration = moment.duration(moment(endTime).diff(moment(startTime)))
    return duration.isValid() ? Math.floor(duration.asSeconds()) : null
}

export function getAppointmentCheckInSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeCheckInStart,
        appointment?._timeCheckInEnd, 
        toNow
    )
}

export function getAppointmentWaitForIntakeSeconds(
    appointment:DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeCheckInEnd, 
        getEarliestTimestampInSet([
            appointment?._timeIntakeStart, 
            appointment?._timeIntakeEnd, 
            appointment?._timeExamStart,
            appointment?._timeExamEnd,
        ]),
        toNow
    )
}

export function getAppointmentIntakeSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeIntakeStart,
        appointment?._timeIntakeEnd,
        toNow
    )
}

export function getAppointmentWaitForExamSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeIntakeEnd,
        getEarliestTimestampInSet([
            appointment?._timeExamStart,
            appointment?._timeExamEnd,
        ]),
        toNow
    )
}

export function getAppointmentInExamSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeExamStart,
        appointment?._timeExamEnd,
        toNow
    )
}

export function getAppointmentWaitForCheckoutSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeExamEnd,
        appointment._timeCheckOutStart,
        toNow
    )
}

export function getAppointmentCheckoutSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): number | null {
    return makeSecondsFromStartEndTimes(
        appointment?._timeCheckOutStart,
        appointment?._timeCheckOutEnd,
        toNow
    )
}


export function getAppointmentStateSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false
): AppointmentDurationsNumber {
    return {
        checkIn: getAppointmentCheckInSeconds(appointment, toNow),
        waitForIntake: getAppointmentWaitForIntakeSeconds(appointment, toNow),
        intake: getAppointmentIntakeSeconds(appointment, toNow),
        waitForExam: getAppointmentWaitForExamSeconds(appointment, toNow),
        exam: getAppointmentInExamSeconds(appointment, toNow),
        waitForCheckout: getAppointmentWaitForCheckoutSeconds(
            appointment,
            toNow
        ),
        checkout: getAppointmentCheckoutSeconds(appointment, toNow),
    }
}


export function getAppointmentTimeInReception(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
    toNow = false,
    ignoreEarlyCheckIn = false
): Duration | null {
    
    const latestPreIntakeTimestamp =  getLatestTimestampInSet([
        ignoreEarlyCheckIn ? appointment?.timeScheduled : null,
        appointment?._timeCheckInStart,
        appointment?._timeCheckInEnd,
    ])

    const earliestIntakeOrExamEvent = getEarliestTimestampInSet([
        appointment?._timeIntakeStart,
        appointment?._timeIntakeEnd,
        appointment?._timeExamStart,
        appointment?._timeExamEnd,
    ])


    return makeDurationFromStartEndTimes(
        latestPreIntakeTimestamp,
        earliestIntakeOrExamEvent,
        toNow
    )
}

export function getAppointmentDurationFromCheckIn(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment
): Duration | null {
    const startKey = getFirstAppointmentStartTimeKey(appointment)
    const endKey = getEarliestAppointmentEndTimeKey(appointment)
    return makeDurationFromStartEndTimes(
        startKey ? appointment?.[startKey] : null,
        endKey ? appointment?.[endKey] : null,
        true
    )
}



export function getAppointmentPredictedDurationsInSeconds(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment,
): AppointmentDurationsNumber {
    const useMLDurations = appointment?.providerDepartmentOptionCompiled?.useMLDurations
    const statsCompiled = appointment?.statsCompiled
    const appointmentPredictedDuration = useMLDurations ? appointment?.appointmentPredictedDuration : null 
    const acc = appointment?.appointmentCycleConfigCompiled
    const durationsInSeconds: any = {
        checkIn: toNumber(appointmentPredictedDuration?.checkInPredictedDuration ?? statsCompiled?.checkInPredictedDuration),
        waitForIntake: toNumber(appointmentPredictedDuration?.waitForIntakePredictedDuration ?? statsCompiled?.waitForIntakePredictedDuration),
        intake: toNumber(appointmentPredictedDuration?.intakePredictedDuration ?? statsCompiled?.intakePredictedDuration),
        waitForExam: toNumber(appointmentPredictedDuration?.waitForDoctorPredictedDuration ?? statsCompiled?.waitForDoctorPredictedDuration),
        exam: toNumber(appointmentPredictedDuration?.inExamPredictedDuration ?? statsCompiled?.inExamPredictedDuration),
        waitForCheckout: toNumber(appointmentPredictedDuration?.waitForCheckOutPredictedDuration ?? statsCompiled?.waitForCheckOutPredictedDuration),
        checkout: toNumber(appointmentPredictedDuration?.checkOutPredictedDuration ?? statsCompiled?.checkOutPredictedDuration),
    }
    const durationsWithPercentageAdjust = {
        checkIn: durationsInSeconds?.checkIn > -1 
            ? (durationsInSeconds.checkIn * (acc?.checkIn?.['adjust_by_percentage'] ? acc?.checkIn?.['estimated_percentage']/100.0 : 1 )) 
            : null,
        waitForIntake: durationsInSeconds?.waitForIntake > -1 
            ? (durationsInSeconds.waitForIntake * (acc?.waitForIntake?.['adjust_by_percentage'] ? acc?.waitForIntake?.['estimated_percentage']/100.0 : 1 )) 
            : null,
        intake: durationsInSeconds?.intake > -1 
            ? (durationsInSeconds.intake * (acc?.intake?.['adjust_by_percentage'] ? acc?.intake?.['estimated_percentage']/100.0 : 1 )) 
            : null,
        waitForExam: durationsInSeconds?.waitForExam > -1 
            ? (durationsInSeconds.waitForExam * (acc?.waitForExam?.['adjust_by_percentage'] ? acc?.waitForExam?.['estimated_percentage']/100.0  : 1 )) 
            : null,
        exam: durationsInSeconds?.exam > -1 
            ? (durationsInSeconds.exam * (acc?.exam?.['adjust_by_percentage'] ? acc?.exam?.['estimated_percentage']/100.0 : 1 )) 
            : null,
        waitForCheckout: durationsInSeconds?.waitForCheckout > -1 
            ? (durationsInSeconds.waitForCheckout * (acc?.waitForCheckout?.['adjust_by_percentage'] ? acc?.waitForCheckout?.['estimated_percentage']/100.0 : 1 )) 
            : null,
        checkout: durationsInSeconds?.checkout > -1 
            ? (durationsInSeconds.checkout * (acc?.checkout?.['adjust_by_percentage'] ? acc?.checkout?.['estimated_percentage']/100.0 : 1 )) 
            : null,
    }
    
    const maxAdjustedDurations = {
        checkIn: acc?.checkIn?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.checkIn?.['estimated_max']), durationsWithPercentageAdjust.checkIn ])) 
            : durationsWithPercentageAdjust.checkIn,
        waitForIntake: acc?.waitForIntake?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.waitForIntake?.['estimated_max']), durationsWithPercentageAdjust.waitForIntake ])) 
            : durationsWithPercentageAdjust.waitForIntake,
        intake: acc?.intake?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.intake?.['estimated_max']), durationsWithPercentageAdjust.intake ])) 
            : durationsWithPercentageAdjust.intake,
        waitForExam: acc?.waitForExam?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.waitForExam?.['estimated_max']), durationsWithPercentageAdjust.waitForExam ])) 
            : durationsWithPercentageAdjust.waitForExam,
        exam: acc?.exam?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.exam?.['estimated_max']), durationsWithPercentageAdjust.exam ])) 
            : durationsWithPercentageAdjust.exam,
        waitForCheckout: acc?.waitForCheckout?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.waitForCheckout?.['estimated_max']), durationsWithPercentageAdjust.waitForCheckout ])) 
            : durationsWithPercentageAdjust.waitForCheckout,
        checkout: acc?.checkout?.['adjust_by_max'] 
            ? Math.max(...compact([toNumber(acc?.checkout?.['estimated_max']), durationsWithPercentageAdjust.checkout ])) 
            : durationsWithPercentageAdjust.checkout,
    }

    return {
        checkIn: acc?.checkIn?.['config'] === 'DEFAULT'  
            ? acc?.checkIn?.['default_duration'] 
            : (acc?.checkIn?.['config'] === 'SKIP' ? null : maxAdjustedDurations.checkIn),
        waitForIntake: acc?.waitForIntake?.['config'] === 'DEFAULT'  
            ? acc?.waitForIntake?.['default_duration'] 
            : (acc?.waitForIntake?.['config'] === 'SKIP' ? null : maxAdjustedDurations.waitForIntake),
        intake: acc?.intake?.['config'] === 'DEFAULT'  
            ? acc?.intake?.['default_duration'] 
            : (acc?.intake?.['config'] === 'SKIP' ? null : maxAdjustedDurations.intake),
        waitForExam: acc?.waitForExam?.['config'] === 'DEFAULT'  
            ? acc?.waitForExam?.['default_duration'] 
            : (acc?.waitForExam?.['config'] === 'SKIP' ? null : maxAdjustedDurations.waitForExam),
        exam: acc?.exam?.['config'] === 'DEFAULT'  
            ? acc?.exam?.['default_duration'] 
            : (acc?.exam?.['config'] === 'SKIP' ? null : maxAdjustedDurations.exam),
        waitForCheckout: acc?.waitForCheckout?.['config'] === 'DEFAULT'  
            ? acc?.waitForCheckout?.['default_duration'] 
            : (acc?.waitForCheckout?.['config'] === 'SKIP' ? null : maxAdjustedDurations.waitForCheckout),
        checkout: acc?.checkout?.['config'] === 'DEFAULT'  
            ? acc?.checkout?.['default_duration'] 
            : (acc?.checkout?.['config'] === 'SKIP' ? null : maxAdjustedDurations.checkout),
    }

}
