import { get, includes, indexOf, startCase, lowerCase } from 'lodash'

import {
    AppointmentStatus,
    CalculatedAppointmentStatus,
    CalculatedAppointmentStatusOrderOfImportance,
    CompletedAppointmentStatuses,
    NotSeenCalculatedAppointmentStatuses,
    NotStartedCalculatedAppointmentStatuses
} from '@docpace/shared-ts-types'

export function isAppointmentPreIntake(appt : { 
    status: string
}): boolean {
    switch (appt?.status) {
        case AppointmentStatus.SCHEDULED:
        case AppointmentStatus.CHECKED_IN:
        case AppointmentStatus.WAIT_FOR_INTAKE:
        case AppointmentStatus.CHECKING_IN: {
            return true
        }
        default:
            return false
    }
}

export function isAppointmentInLagState(appt : { 
    status: string
}): boolean {
    switch (appt?.status) {
        case AppointmentStatus.CHECKED_IN:
        case AppointmentStatus.WAIT_FOR_INTAKE:
        case AppointmentStatus.WAIT_FOR_DOCTOR:
        case AppointmentStatus.WAIT_FOR_CHECKOUT: {
            return true
        }
        default:
            return false
    }
}

export function appointmentToCalculatedAppointmentStatus(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): CalculatedAppointmentStatus {
    if (
        appt?.status === AppointmentStatus.SCHEDULED &&
        !!appt.patientManualCheckIn
    ) {
        return CalculatedAppointmentStatus.Arrived
    }

    if (
        appt?.status === AppointmentStatus.CANCELED &&
        !!appt?.rescheduledAppointmentId
    ) {
        return CalculatedAppointmentStatus.Rescheduled
    }
    return (
        CalculatedAppointmentStatus[
            startCase(lowerCase(appt?.status ?? undefined))?.split(' ').join('')
        ] ?? CalculatedAppointmentStatus.Unknown
    )
}

export function appointmentToCalculatedAppointmentStatusIndex(
    appt : { 
        status: string
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): number {
    return indexOf(
        CalculatedAppointmentStatusOrderOfImportance,
        appointmentToCalculatedAppointmentStatus(appt)
    )
}

export function appointmentToStatusString(
    appt : { 
        status: string
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): string {
    return calculatedAppointmentStatusToString(
        appointmentToCalculatedAppointmentStatus(appt)
    )
}

export function calculatedAppointmentStatusToStrings(
    calculatedStatus: CalculatedAppointmentStatus
): string[] {
    switch (calculatedStatus) {
        case CalculatedAppointmentStatus.Scheduled:
            return ['Pending']
        case CalculatedAppointmentStatus.Arrived:
            return ['Arrived']
        case CalculatedAppointmentStatus.CheckingIn:
            return ['Checking In']
        case CalculatedAppointmentStatus.CheckedIn:
            return ['Checked In']
        case CalculatedAppointmentStatus.WaitForIntake:
            return ['Ready for', 'Staff']
        case CalculatedAppointmentStatus.Intake:
            return ['Intake']
        case CalculatedAppointmentStatus.WaitForDoctor:
            return ['Ready for', 'Provider']
        case CalculatedAppointmentStatus.Exam:
            return ['In Exam']
        case CalculatedAppointmentStatus.WaitForCheckout:
            return ['Ready for', 'Checkout']
        case CalculatedAppointmentStatus.CheckingOut:
            return ['Checking Out']
        case CalculatedAppointmentStatus.CheckedOut:
            return ['Done']
        case CalculatedAppointmentStatus.Canceled:
            return ['Canceled']
        case CalculatedAppointmentStatus.LeftWithoutBeingSeen:
            return ['Left', 'Not Seen']
        case CalculatedAppointmentStatus.Rescheduled:
            return ['Rescheduled']
        case CalculatedAppointmentStatus.NoShow:
            return ['No Show']
        default:
            return ['Unknown']
    }
}

export function calculatedAppointmentStatusToString(
    calculatedStatus: CalculatedAppointmentStatus
): string {
    return calculatedAppointmentStatusToStrings(calculatedStatus).join(' ')
}

export function compareAppointmentStatus(
    a: CalculatedAppointmentStatus,
    b: CalculatedAppointmentStatus
) {
    const aIdx = CalculatedAppointmentStatusOrderOfImportance.indexOf(a)
    const bIdx = CalculatedAppointmentStatusOrderOfImportance.indexOf(b)

    return aIdx - bIdx
}

export function isAppointmentInNotSeenState(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    return includes(
        NotSeenCalculatedAppointmentStatuses,
        appointmentToCalculatedAppointmentStatus(appt)
    )
}

export function isAppointmentInNotStartedState(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    return includes(
        NotStartedCalculatedAppointmentStatuses,
        appointmentToCalculatedAppointmentStatus(appt)
    )
}

export function isAppointmentInCompletedState(
    appt : { status?: (string | AppointmentStatus) }
): boolean {
    return includes(CompletedAppointmentStatuses, appt?.status)
}

export function isAppointmentActualEndTimeIndeterminate(
    appt : { 
        status: string, 
        pausedAt?: any | null
        patientManualCheckIn?: any | null
        rescheduledAppointmentId?: string | null | undefined
    }
): boolean {
    if (appt.pausedAt) return false
    const calculatedAppointmentStatus = appointmentToCalculatedAppointmentStatus(appt)
    if (
        includes(
            [
                CalculatedAppointmentStatus.Scheduled,
                CalculatedAppointmentStatus.Arrived,
                CalculatedAppointmentStatus.CheckingIn,
                CalculatedAppointmentStatus.CheckedIn,
                CalculatedAppointmentStatus.WaitForIntake,
                CalculatedAppointmentStatus.Intake,
                CalculatedAppointmentStatus.WaitForDoctor,
                CalculatedAppointmentStatus.Exam,
                CalculatedAppointmentStatus.WaitForCheckout,
                CalculatedAppointmentStatus.CheckingOut,
            ],
            calculatedAppointmentStatus
        )
    )
        return true

    return false
}

export function isAppointmentActualEndTimeDeterminate(appt : { 
    status: string
    pausedAt?: any | null
    patientManualCheckIn?: any | null
    rescheduledAppointmentId?: string | null | undefined
}): boolean {
    const calculatedAppointmentStatus = appointmentToCalculatedAppointmentStatus(appt)
    if (appt.pausedAt) return false
    if (
        includes(
            [
                CalculatedAppointmentStatus.WaitForCheckout,
                CalculatedAppointmentStatus.CheckingOut,
                CalculatedAppointmentStatus.CheckedOut,
            ],
            calculatedAppointmentStatus
        )
    )
        return true

    return false
}

export function isAppointmentPendingOrCheckedIn({
    status
} : { status: string }): boolean {
    switch (status) {
        case AppointmentStatus.SCHEDULED:
        case AppointmentStatus.CHECKING_IN:
        case AppointmentStatus.CHECKED_IN: {
            return true
        }
        default:
            return false
    }
}

export function getTimelineStepIndex(calculatedStatus: CalculatedAppointmentStatus): number {
    switch (calculatedStatus) {
        case CalculatedAppointmentStatus.Scheduled:
            return -1
        case CalculatedAppointmentStatus.Arrived:
            return 1
        case CalculatedAppointmentStatus.CheckingIn:
            return 0
        case CalculatedAppointmentStatus.CheckedIn:
            return 1
        case CalculatedAppointmentStatus.WaitForIntake:
            return 1
        case CalculatedAppointmentStatus.Intake:
            return 2
        case CalculatedAppointmentStatus.WaitForDoctor:
            return 3
        case CalculatedAppointmentStatus.Exam:
            return 4
        case CalculatedAppointmentStatus.WaitForCheckout:
            return 5
        case CalculatedAppointmentStatus.CheckingOut:
            return 6
        case CalculatedAppointmentStatus.CheckedOut:
            return 7
        case CalculatedAppointmentStatus.Canceled:
            return -1
        case CalculatedAppointmentStatus.LeftWithoutBeingSeen:
            return -1
        case CalculatedAppointmentStatus.Rescheduled:
            return -1
        case CalculatedAppointmentStatus.NoShow:
            return -1
        default:
            return -1
    }
}

export function isAppointmentDone({
    status
} : { status: string }): boolean {
    switch (status) {
        case AppointmentStatus.CHECKED_OUT:
            return true
        default:
            return false
    }
}
