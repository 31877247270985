
import { CloseCircle } from '@docpace/shared-react-icons'
import { useManagerRequestFeedback } from '@docpace/manager-react-hooks'

interface ManagerRequestFeedbackProps {}

export const ManagerRequestFeedback = (props: ManagerRequestFeedbackProps = {})=>{
    const {
        handleAcceptFeedbackRequest,
        handleDismissFeedbackRequest
    } = useManagerRequestFeedback()
    return (
        <div className='w-full flex justify-between p-0.5 items-center' style={{ backgroundColor: '#91f0fa'}}>
            <div></div>
            <div>
                How are we doing? You can leave feedback from the settings page at any time, or by clicking 
                <button 
                    children='here.'
                    onClick={handleAcceptFeedbackRequest}
                    className='btn btn-sm btn-ghost underline px-1 normal-case text-lg'
                /> 
            </div>
            <div>
                <CloseCircle className='h-5 w-5 mr-2' onClick={handleDismissFeedbackRequest}/>
            </div>
        </div>
    )
}