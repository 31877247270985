import React, { FC } from 'react'
import { find, includes } from 'lodash'
import { useSnackbar } from 'notistack'
import {
    ManagerDetailFragment,
    ProviderOverviewFragment,
} from '@docpace/shared-graphql/fragments'
import { useAppointmentCheckInsSubscription } from '@docpace/manager-react-apollo'
import { hasManagerPermission } from '@docpace/shared-ts-utils'

interface CheckInNotifierProps {
    provider: ProviderOverviewFragment
    providers: ProviderOverviewFragment[]
    currentPageProviderIds: string[]
    usePatientInitials?: boolean
    manager: ManagerDetailFragment
}

export const CheckInNotifier: FC<CheckInNotifierProps> = ({
    provider,
    currentPageProviderIds,
    providers,
    usePatientInitials,
    manager
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { providerId } = provider ?? {}

    useAppointmentCheckInsSubscription({
        variables: { providerId },
        onData: ({ data }) => {
            const appointment = data?.data?.appointmentCheckedIn?.appointment
            const patient = appointment?.patient

            // skip the notification if the manager role is in use and does not contain the correct permissions
            if (
                manager && !hasManagerPermission({
                    providerId: appointment?.scheduleProviderId,
                    departmentId: appointment?.departmentId,
                }, manager)
            ) return 

            const providerId = appointment?.scheduleProviderId
            const provider = find(providers, { providerId })
            const patientName = usePatientInitials
                ? patient?.nameInitials
                : patient?.nameDisplay
            let message = `Patient ${patientName} checked in`

            // if the page is supporting multiple providers or the providerId is not in the current page context, append the provider name
            if (
                (!includes(currentPageProviderIds, providerId) ||
                    currentPageProviderIds?.length > 1) &&
                provider
            ) {
                message += ` for provider ${provider?.displayName}`
            }

            enqueueSnackbar(message, { variant: 'info' })
        },
    })

    return <></>
}
