import React from 'react'
import { useRouter } from 'next/router'
import { get, isString, toString, isInteger, toInteger } from 'lodash'

export const useNextQueryParam: (
    key: string,
    defaultValue?: any
) => string | null = (key, defaultValue) => {
    const router = useRouter()
    let param = get(router?.query, key)
    param = isString(param) ? param : param?.[0]
    if (!param || param === '')
        return typeof defaultValue !== 'undefined'
            ? toString(defaultValue)
            : null
    return param
}
