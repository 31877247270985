import { FC, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useQuery, QueryHookOptions } from '@apollo/client'

import { ManagerBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerBaseQueryResultFragment } from '@docpace/manager-graphql'
import { ctxAdminIdAtom, ctxAdminOverrideManagerId, ctxManagerIdAtom, ctxPracticeIdAtom } from '@docpace/shared-react-atoms'

export function useManagerBaseQuery(opts?: QueryHookOptions<ManagerBaseQueryResultFragment>){
    return useQuery<ManagerBaseQueryResultFragment>(ManagerBaseQueryDocument, opts)
}

export interface UseContextManagerBaseQueryParams {
    isAuthPage?: boolean
}

export function useContextManagerBaseQuery(params?: UseContextManagerBaseQueryParams){
    
    const { isAuthPage } = params ?? {}
    const [ practiceId, setCtxPracticeId ] = useAtom(ctxPracticeIdAtom)
    const [ adminId, setCtxAdminId ] = useAtom(ctxAdminIdAtom)
    const [ managerId, setCtxManagerId ] = useAtom(ctxManagerIdAtom)
    const [ overrideManagerId ] = useAtom(ctxAdminOverrideManagerId)

    function setContextIds({ practiceId, adminId, managerId }: ManagerBaseQueryResultFragment){
        setCtxPracticeId(practiceId ?? undefined)
        setCtxAdminId(overrideManagerId ? undefined : (adminId ?? undefined))
        setCtxManagerId(overrideManagerId ?? managerId ?? undefined)
    }

    const managerBaseQueryResult = useManagerBaseQuery({
        skip: isAuthPage,
        onCompleted: setContextIds,
    })
   
    return {
        ...managerBaseQueryResult,
        practiceId, 
        // prevent anything downstream in the context from receiving an admin if 
        // a manager account override is being used
        adminId: overrideManagerId ? null : adminId, 
        // escape hatch for determining admin for the header dropdown
        _adminId: adminId,
        managerId,
        serverInfo: managerBaseQueryResult?.data?.serverInfo,
        tableauLinks: managerBaseQueryResult?.data?.tableauLinks
    }
}