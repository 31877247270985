import { ctxDateObjectAtom, ctxDateObjectEffectsAtom } from '@docpace/shared-react-atoms'
import { useAtom } from 'jotai'
import Interval from 'react-interval'

interface SharedContextDateTickerIntervalComponentProps {
    timeout?: number
}

export const SharedContextDateTickerIntervalComponent = ({
    timeout = 1000 // default 1s
}: SharedContextDateTickerIntervalComponentProps)=>{
    useAtom(ctxDateObjectEffectsAtom)
    const [ , setCtxDateObject ] = useAtom(ctxDateObjectAtom)
    return <Interval
        enabled
        timeout={timeout}
        callback={async () => setCtxDateObject(new Date())}
    />
}