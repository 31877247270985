import { useQuery, QueryHookOptions } from '@apollo/client'
import { ManagerAdminBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerAdminBaseQueryResultFragment, ManagerAdminBaseQueryParams } from '@docpace/manager-graphql'
import { useAtom } from 'jotai'
import { ctxAdminIdAtom } from '@docpace/shared-react-atoms'

export function useManagerAdminBaseQuery(opts?: QueryHookOptions<ManagerAdminBaseQueryResultFragment, Partial<ManagerAdminBaseQueryParams>>){
    return useQuery<ManagerAdminBaseQueryResultFragment>(ManagerAdminBaseQueryDocument, opts)
}

export function useContextManagerAdminBaseQuery(){
    const [ adminId ] = useAtom(ctxAdminIdAtom)
    const queryResult = useManagerAdminBaseQuery({
        variables: { adminId },
        skip: !adminId,
    })

    return {
        ...queryResult,
        admin: queryResult?.data?.admin
    }
}