'use client'
import { useEffect, useState } from 'react'
import { memoize, toNumber } from 'lodash'
import { atom, useAtom } from 'jotai'
import { 
    getWebInstrumentations, 
    initializeFaro, 
    Faro, 
    PushErrorOptions, 
    PushLogOptions, 
    PushMeasurementOptions, 
    TraceEvent,
    EventAttributes,
    MetaView,
    SessionInstrumentation,
    BaseInstrumentation,
    ViewInstrumentation,
    ErrorsInstrumentation,
    ConsoleInstrumentation,
    WebVitalsInstrumentation,
    PerformanceInstrumentation,
} from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'

import { AccountAuthResponseCamel } from '@docpace/shared-nodejs-server'
import useIsChangingRoute from './useIsChangingRoute'
import { useRouter } from 'next/router'

interface FaroAppConfigInterface { name: string, version: string, environment: string }

const makeFaroClient = memoize((app: FaroAppConfigInterface | null, url)=>{
    return initializeFaro({
        app: app ?? {},
        sessionTracking: {
            samplingRate: 0.99
        },
        beforeSend: (log)=>{
            const { meta, payload, type } = log 
            if(window.location.hostname === 'localhost') {
                return null
            }
            return log
        },
        isolate: true,
        url,
        instrumentations: [
            new SessionInstrumentation(),
            new ViewInstrumentation(),
            new ErrorsInstrumentation(),
            new ConsoleInstrumentation(),
            // new WebVitalsInstrumentation(),
            // new PerformanceInstrumentation(),
            // new TracingInstrumentation() // increases bundle size
        ],
    })
})

export const faroClientAtom = atom<Faro | any>({})
export const faroClientUserAtom = atom<any>({})
export const faroAppConfig = {
    name: '',
    version: '0.0.1',
    environment: (typeof window !== 'undefined' && window.location.hostname === 'localhost') ? 'production' : 'production'
}

interface UseInitializeFaroClientProps {
    appName: string
    practiceId: string
    userId: any
    url: string
}

export function useInitializeFaroClient({
    appName, url, practiceId, userId
}: UseInitializeFaroClientProps){
    const [ requestStart, setRequestStart ] = useState<number>((new Date()).getMilliseconds())
    const [ faroClient, setFaroClient ] = useAtom(faroClientAtom)
    const [ isInitialized, setIsInitialized ] = useState<boolean>(false)
    const { pushFaroEvent, setFaroView, pushFaroMeasurement } = useFaro()
    const { pathname } = useRouter()
    const [ faroUser, setFaroUser ] = useAtom(faroClientUserAtom)
    const { isChangingRoute } = useIsChangingRoute()

    useEffect(()=>{
        const _faroUser: any = faroUser
        faroClient?.api?.setUser(_faroUser)
    }, [ faroUser, faroClient ])

    useEffect(()=>{
        if(!isChangingRoute){
            setFaroView({ name: pathname })
        }
    }, [ isChangingRoute ])

    useEffect(()=>{
        if(isChangingRoute){
            setRequestStart((new Date()).getMilliseconds())
        } else {
            const requestEnd = (new Date()).getMilliseconds()
            pushFaroMeasurement({ type: 'page-load-time', values: { time_ms: toNumber(requestEnd - requestStart) } })
        }
    }, [ isChangingRoute ])

    useEffect(()=>{
        setFaroUser({ userId, practiceId })
    }, [ userId, practiceId ])
    
    useEffect(()=>{
        if (typeof window !== 'undefined'){
            setFaroClient(makeFaroClient({
                ...faroAppConfig,
                name: appName + (typeof window === 'undefined' ? '' : window.location.hostname === 'localhost' ? '-local' : ''),
            }, url))
            setIsInitialized(true)
        }
    }, [ faroAppConfig, typeof window ])

    return {
        faroClient
    }
}

export function useFaro(){
    
    const [ faroClient ] = useAtom<Faro | null, any, any>(faroClientAtom)
        function _pushError(value: Error, options?: PushErrorOptions | undefined){}
        function _pushLog(args: any[], options?: PushLogOptions | undefined){}
        function _pushEvent(name: string, attributes?: EventAttributes | undefined, domain?: string | undefined, options?: any | undefined){}
        function _pushTraces(traces: TraceEvent){}
        function _pushMeasurement(args: { type: string, values: any }, options?: PushMeasurementOptions | undefined){}
        function _setView(view?: MetaView | undefined){}
        return {
            pushFaroError: faroClient?.api?.pushError ?? _pushError,
            pushFaroLog: faroClient?.api?.pushLog ?? _pushLog,
            pushFaroEvent: faroClient?.api?.pushEvent ?? _pushEvent,
            pushFaroTraces: faroClient?.api?.pushTraces ?? _pushTraces,
            pushFaroMeasurement: faroClient?.api?.pushMeasurement ?? _pushMeasurement,
            setFaroView: faroClient?.api?.setView ?? _setView
        }
}


