import { useCallback } from 'react'
import { isAfter, isBefore, subMonths } from 'date-fns'

import { useDialogAtom, useManagerBase } from '@docpace/shared-react-hooks'
import { ContactSupportFormAtom } from '@docpace/manager-react-components/ContactSupportForm'
import { CloseCircle } from '@docpace/shared-react-icons'

interface UseManagerRequestFeedbackProps {}

export function useManagerRequestFeedback(props:UseManagerRequestFeedbackProps = {}){
   
    const { actor, manager, admin, updateManagerDashboardSettings } = useManagerBase()
    const { doOpen: doOpenSupportForm } = useDialogAtom(ContactSupportFormAtom)

    const shouldDisplayFeedbackRequest = actor && (!actor?.managerTimeLastRequestedFeedback || isBefore(
        actor?.managerTimeLastRequestedFeedback,
        subMonths(new Date(), 1)
    ))
    
    const handleDismissFeedbackRequest = async ()=>{
        await updateManagerDashboardSettings('timeLastRequestedFeedback', new Date())
    }

    const handleAcceptFeedbackRequest = async ()=>{
        doOpenSupportForm({
            email: manager?.email ?? admin?.email, subject: 'I\'d like to share my feedback'
        })
        await updateManagerDashboardSettings('timeLastRequestedFeedback', new Date())
    }

    const FeedbackRequestComponent = useCallback(()=>{
        return (
            <div className='w-full flex justify-between p-0.5 items-center' style={{ backgroundColor: '#91f0fa'}}>
                <div></div>
                <div>
                    How are we doing? You can leave feedback from the settings page at any time, or by clicking 
                    <button 
                        children='here.'
                        onClick={handleAcceptFeedbackRequest}
                        className='btn btn-sm btn-ghost underline px-1 normal-case text-lg'
                    /> 
                </div>
                <div>
                    <CloseCircle className='h-5 w-5 mr-2' onClick={handleDismissFeedbackRequest}/>
                </div>
            </div>
        )
    }, [])

    return {
        handleDismissFeedbackRequest,
        handleAcceptFeedbackRequest,
        shouldDisplayFeedbackRequest,
        FeedbackRequestComponent
    }
}