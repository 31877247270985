import React from 'react'
import {
    AiOutlineGlobal,
    AiOutlineSetting,
    AiOutlineTranslation,
    AiOutlineUser,
    AiOutlineStar,
    AiFillStar,
    AiOutlineCalendar,
    AiOutlineUserDelete,
} from 'react-icons/ai/'
import {
    BiNetworkChart,
    BiFingerprint,
    BiCalendar,
    BiCalendarEvent,
    BiMessageError,
    BiCheckbox,
    BiCheckboxChecked,
    BiCheckboxMinus,
    BiExit,
    BiMessageX,
} from 'react-icons/bi/index'

import {
    BsArrowDown,
    BsArrowDownCircle,
    BsArrowLeft,
    BsArrowRight,
    BsArrowUp,
    BsCameraVideo,
    BsChevronLeft,
    BsChevronRight,
    BsTablet,
    BsFileEarmarkCheck,
    BsSpeedometer,
} from 'react-icons/bs/'
import {
    CiMail,
} from 'react-icons/ci/'
import {
    FaBuilding,
    FaCamera,
    FaCopy,
    FaHome,
    FaMapMarkerAlt,
    FaPhone,
    FaPhoneSlash,
    FaUserMd,
    FaUserShield,
    FaUserTie,
    FaLink,
    FaSpinner,
    FaHospitalUser,
    FaUserClock,
    FaUserCheck,
} from 'react-icons/fa/'
import {
    FaUserDoctor,
    FaForward,
    FaForwardStep,
    FaCaretLeft,
    FaCaretRight,
    FaBackward
} from 'react-icons/fa6/'
import { FiActivity, FiPlay, FiRefreshCw, FiPercent } from 'react-icons/fi/'
import { GiSiren } from 'react-icons/gi/'
import { GrLinkTop, GrMap } from 'react-icons/gr/'

import {
    IoIosPaperPlane,
    IoMdArrowRoundBack,
    IoMdClock,
    IoMdArrowForward,
    IoMdInformationCircleOutline,
} from 'react-icons/io/'

import {
    LiaCcVisa,
} from 'react-icons/lia/'
import {
    RiPushpin2Fill,
    RiPushpinLine,
    RiHospitalLine,
    RiCalendarTodoLine,
    RiCloseCircleLine,
    RiCheckboxMultipleLine,
    RiLoader4Line,
} from 'react-icons/ri/'
import { ImArrowDown2, ImStatsBars, ImWarning } from 'react-icons/im/'
import { IoIosPin } from 'react-icons/io/'
import {
    HiChatAlt2,
    HiDotsHorizontal,
    HiDotsVertical,
    HiLogout,
    HiSortAscending,
    HiSortDescending,
} from 'react-icons/hi/'
import { FcSmartphoneTablet } from 'react-icons/fc/'
import {
    MdGroupAdd,
    MdGroups,
    MdPauseCircleOutline,
    MdOutlineSmsFailed,
    MdSms,
    MdMonitor,
    MdFreeCancellation,
    MdCheckCircleOutline,
    MdOutlineHub,
    MdOutlineRoomPreferences,
} from 'react-icons/md/'

// import { makeIconReplacement } from '@docpace/types'

import { 
    TfiLayoutListThumb,
    TfiAlarmClock
 } from 'react-icons/tfi/'

export const makeIconReplacement = (Icon: any) => (props: any) => {
    // some icons throw pId errors when used directly - load them into the Icons
    // const using this function to remove the problematic pId attribute.
    const {
        props: {
            attr: { pId, ...rest },
            children: [defs, ...paths],
        },
    } = Icon()

    const SAFE_PATHS = paths.map(
        ({ type, props: { pId: pathId, ...pathProps } }: any) =>
            React.createElement(type, pathProps)
    )
    const SAFE_CHILDREN = React.Children.toArray([defs, ...SAFE_PATHS])
    const SAFE_PROPS = { attr: rest, ...props }

    return React.cloneElement(Icon(), SAFE_PROPS, SAFE_CHILDREN)
}

export const AddGroupIcon = MdGroupAdd
export const GroupIcon = MdGroups
export const AdminIcon = FaUserShield
export const ActivityIcon = FiActivity
export const ArrowDownIcon = BsArrowDown
export const ArrowLeftIcon = BsArrowLeft
export const ArrowRightIcon = BsArrowRight
export const ArrowDownBoldIcon = ImArrowDown2
export const ArrowDownCircleOutlineIcon = BsArrowDownCircle
export const ArrowUpIcon = BsArrowUp
export const BackwardIcon = FaCaretLeft
export const LogoutIcon = HiLogout
export const AppointmentIcon = BiCalendar
export const AppointmentTypeIcon = BiCalendarEvent
export const CalendarIcon = AiOutlineCalendar
export const CCVisaIcon = LiaCcVisa
export const ChatIcon = HiChatAlt2
export const Checkbox = BiCheckbox
export const CheckboxChecked = BiCheckboxChecked
export const CheckboxMinus = BiCheckboxMinus
export const CheckboxMultiple = RiCheckboxMultipleLine
export const CheckCircleOutline = MdCheckCircleOutline
export const CloseCircle = RiCloseCircleLine
export const ClockIcon = IoMdClock
export const CopyIcon = FaCopy
export const CameraIcon = BsCameraVideo
export const DemoIcon = BiNetworkChart
export const DemoAppointmentIcon = RiCalendarTodoLine
export const DepartmentIcon = FaBuilding
export const DeviceIcon = BsTablet
export const ExitIcon = BiExit
export const FastForwardEndIcon = FaForwardStep
export const FastForwardIcon = FaForward
export const ForwardIcon = FaCaretRight
export const FastBackwardIcon = FaBackward
export const GlobalIcon = AiOutlineGlobal
export const HeaderBackIcon = IoMdArrowRoundBack
export const InformationCircleOutlineIcon = IoMdInformationCircleOutline
export const MailIcon = CiMail
export const MenuDotsVerticalIcon = HiDotsVertical
export const MenuDotsHorizontalIcon = HiDotsHorizontal
export const PracticeIcon = RiHospitalLine
export const PracticePatientLocationIcon = MdOutlineRoomPreferences
export const ProfileIcon = AiOutlineUser
export const ProviderIcon = FaUserMd
export const ChevronLeftIcon = BsChevronLeft
export const ChevronRightIcon = BsChevronRight
export const LinkedApptIcon = FaLink
export const NoticeIcon = MdOutlineSmsFailed
export const ManagerIcon = FaUserTie
export const MapPinIcon = FaMapMarkerAlt
export const MapPinIcon2 = GrMap
export const NoShowIcon = AiOutlineUserDelete
export const MonitorIcon = MdMonitor
export const PaperPlaneIcon = IoIosPaperPlane
export const PatientIcon = AiOutlineUser
export const PauseIcon = MdPauseCircleOutline
export const PercentIcon = FiPercent
export const PhoneDisabledIcon = FaPhoneSlash
export const PhoneIcon = FaPhone
export const SMSDisabledIcon = BiMessageX
export const SMSFailedIcon = BiMessageError
export const SMSIcon = MdSms
export const PatientConfirmationPositiveIcon = FaUserCheck
export const PatientConfirmationIndeterminateIcon = FaUserClock
export const PinIcon = IoIosPin
export const PlayIcon = FiPlay
export const PushpinPinnedIcon = RiPushpin2Fill
export const PushpinUnpinnedIcon = RiPushpinLine
export const RefreshIcon = FiRefreshCw
export const RefreshingIcon = FiRefreshCw
export const SettingsIcon = AiOutlineSetting
export const SessionIcon = BiFingerprint
export const SkipIcon = IoMdArrowForward
export const SmartphoneColorIcon = FcSmartphoneTablet
export const SortUp = HiSortAscending
export const SortDown = HiSortDescending
export const SpinIcon = FaSpinner
export const SpinIconPatient = RiLoader4Line
export const StatsIcon = ImStatsBars
export const StarFilledIcon = AiFillStar
export const StarOutlineIcon = AiOutlineStar
export const TermsOfUseIcon = BsFileEarmarkCheck
export const TranslationIcon = makeIconReplacement(AiOutlineTranslation)
export const UpperLimitIcon = GrLinkTop
export const UrgentIcon = GiSiren
export const WarningIcon = ImWarning

export const InsightsHomeIcon = MdOutlineHub
export const InsightsPerformanceIcon = TfiLayoutListThumb
export const InsightsDeliveryIcon = TfiAlarmClock
export const InsightsGrowthIcon = FaHospitalUser
export const InsightsProductivityIcon = BsSpeedometer
export const InsightsAdherenceIcon = MdFreeCancellation
export const InsightsProviderSummaryIcon = FaUserMd
export const InsightsPatientFlowIcon = AiOutlineUser
export const InsightsMonthlyIcon = BiCalendar

export default {}
