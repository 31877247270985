import { FC, useState } from 'react'
import { useSnackbar } from 'notistack'
import { uniq, includes } from 'lodash'
import Moment from 'moment-timezone'

import {
    formatDelay,
    AppointmentStatus,
    isAppointmentAfterWaitForExamDelayNotificationThreshold,
    isAppointmentAfterIntakeDelayNotificationThreshold,
    isAppointmentAfterTimeInReceptionDelayNotificationThreshold,
    AppointmentDelayConfigProps,
} from '@docpace/shared-ts-types'
import {
    isAppointmentInLagState,
    hasManagerPermission
} from '@docpace/shared-ts-utils'
import {
    ManagerDetailFragment,
    ManagerNotificationMethod,
    ProviderOverviewFragment,
} from '@docpace/shared-graphql/fragments'
import { useAppointmentUpdatesSubscription, useProviderAppointmentExamDelaysSubscription, useProviderAppointmentIntakeDelaysSubscription } from '@docpace/manager-react-apollo'


interface ProviderAppointmentDelayNotifierProps {
    provider: ProviderOverviewFragment
    isSelected?: boolean
    usePatientInitials?: boolean
    delayConfig: AppointmentDelayConfigProps
    manager: ManagerDetailFragment
}

export const ProviderAppointmentDelayNotifier: FC<
    ProviderAppointmentDelayNotifierProps
> = ({ provider, isSelected, usePatientInitials, delayConfig, manager }) => {
    const { providerId } = provider ?? {}
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const {
        notifyTimeInReceptionDelays,
        notifyTimeInReceptionDelaysMethod,
        notifyTimeInReceptionDelaysAfterMinutes,
        ignoreTimeInReceptionEarlyCheckin,
        notifyWaitForIntakeDelays,
        notifyWaitForIntakeDelaysMethod,
        notifyWaitForIntakeDelaysAfterMinutes,
        notifyWaitForDoctorDelays,
        notifyWaitForDoctorDelaysMethod,
        notifyWaitForDoctorDelaysAfterMinutes,
    } = delayConfig ?? {}

    const [
        dismissedWaitForIntakeNotifications,
        setDismissedWaitForIntakeNotifications,
    ] = useState<string[]>([])
    const [
        dismissedWaitForDoctorNotifications,
        setDismissedWaitForDoctorNotifications,
    ] = useState<string[]>([])
    const [
        dismissedTimeInReceptionNotifications,
        setDismissedTimeInReceptionNotifications,
    ] = useState<string[]>([])

    useProviderAppointmentIntakeDelaysSubscription({
        variables: { providerId },
        skip:
            !providerId ||
            notifyWaitForIntakeDelaysMethod !==
                ManagerNotificationMethod.Snackbar,
        onData: async ({
            data,
        }: {
            data: any
        }) => {
            const appointment = data?.data?.appointmentIntakeDelays?.appointment
            const { appointmentId, patient, provider } = appointment ?? {}
            
            // skip the notification if the manager is present and permissions are missing
            if (manager && !hasManagerPermission({
                providerId: appointment?.scheduleProviderId,
                departmentId: appointment?.departmentId,
            }, manager)) return

            if (
                notifyTimeInReceptionDelays &&
                !includes(
                    dismissedTimeInReceptionNotifications,
                    appointmentId
                ) &&
                isAppointmentAfterTimeInReceptionDelayNotificationThreshold(
                    appointment,
                    notifyTimeInReceptionDelaysAfterMinutes,
                    ignoreTimeInReceptionEarlyCheckin
                )
    
            ) {
                const patientName = usePatientInitials
                    ? patient?.nameInitials
                    : patient?.nameDisplay
                const providerString = !isSelected
                    ? ` for ${provider?.displayName}`
                    : ''
                enqueueSnackbar(
                    `Patient ${patientName} has been in reception for > ${formatDelay(
                        notifyTimeInReceptionDelaysAfterMinutes,
                        { leadingZeros: false }
                    )} for intake${providerString}`,
                    {
                        key: `appointment:${appointment?.appointmentId}:timeInReceptionDelay`,
                        preventDuplicate: true,
                        variant: 'warning',
                        className:
                            '!text-white !bg-red-500 contrast-[0.8] brightness-[0.9]',
                        persist: true,
                        onClose: (ev) => {
                            setDismissedTimeInReceptionNotifications(
                                uniq([
                                    ...dismissedTimeInReceptionNotifications,
                                    appointment?.appointmentId,
                                ])
                            )
                        },
                        action: (snackbarId) => (
                            <div>
                                <button
                                    className="btn btn-ghost"
                                    onClick={() => closeSnackbar(snackbarId)}
                                >
                                    Dismiss
                                </button>
                            </div>
                        ),
                    }
                )
            }

            if (
                notifyWaitForIntakeDelays &&
                includes(
                    [
                        AppointmentStatus.CHECKED_IN,
                        AppointmentStatus.WAIT_FOR_INTAKE,
                    ],
                    appointment?.status
                ) &&
                !includes(dismissedWaitForIntakeNotifications, appointmentId) &&
                isAppointmentAfterIntakeDelayNotificationThreshold(
                    appointment,
                    notifyWaitForIntakeDelaysAfterMinutes
                )
            ) {
                const patientName = usePatientInitials
                    ? patient?.nameInitials
                    : patient?.nameDisplay
                const providerString = !isSelected
                    ? ` for ${provider?.displayName}`
                    : ''
                enqueueSnackbar(
                    `Patient ${patientName} has been waiting for > ${formatDelay(
                        notifyWaitForIntakeDelaysAfterMinutes,
                        { leadingZeros: false }
                    )} for intake${providerString}`,
                    {
                        key: `appointment:${appointment?.appointmentId}:waitForIntakeDelay`,
                        preventDuplicate: true,
                        variant: 'warning',
                        className:
                            '!text-black !bg-orange-400 contrast-[1.2] brightness-[1.2]',
                        persist: true,
                        onClose: (ev) => {
                            setDismissedWaitForIntakeNotifications(
                                uniq([
                                    ...dismissedWaitForIntakeNotifications,
                                    appointment?.appointmentId,
                                ])
                            )
                        },
                        action: (snackbarId) => (
                            <div>
                                <button
                                    className="btn btn-ghost"
                                    onClick={() => closeSnackbar(snackbarId)}
                                >
                                    Dismiss
                                </button>
                            </div>
                        ),
                    }
                )
            }
        },
    })

    useProviderAppointmentExamDelaysSubscription({
        variables: { providerId },
        skip:
            !providerId ||
            notifyWaitForDoctorDelaysMethod !==
                ManagerNotificationMethod.Snackbar,
        onData: async ({
            data,
        }: {
            data: any
        }) => {
            const appointment = data?.data?.appointmentExamDelays?.appointment
            const { appointmentId, patient, provider } = appointment ?? {}

            // skip the notification if the manager is present and permissions are missing
            if (manager && !hasManagerPermission({
                providerId: appointment?.scheduleProviderId,
                departmentId: appointment?.departmentId,
            }, manager)) return
            
            if (
                notifyWaitForDoctorDelays &&
                !includes(dismissedWaitForDoctorNotifications, appointmentId) &&
                isAppointmentAfterWaitForExamDelayNotificationThreshold(
                    appointment,
                    notifyWaitForDoctorDelaysAfterMinutes
                )
            ) {
                const patientName = usePatientInitials
                    ? patient?.nameInitials
                    : patient?.nameDisplay
                const providerString = !isSelected
                    ? ` with ${provider?.displayName}`
                    : ''
                enqueueSnackbar(
                    `Patient ${patientName} has been waiting for > ${formatDelay(
                        notifyWaitForDoctorDelaysAfterMinutes,
                        { leadingZeros: false }
                    )} for exam${providerString}`,
                    {
                        key: `appointment:${appointment?.appointmentId}:waitForDoctorDelay`,
                        preventDuplicate: true,
                        variant: 'warning',
                        persist: true,
                        className:
                            '!text-black !bg-lime-200 contrast-[1.06] brightness-[1.08]',
                        onClose: (ev) => {
                            setDismissedWaitForDoctorNotifications(
                                uniq([
                                    ...dismissedWaitForDoctorNotifications,
                                    appointment?.appointmentId,
                                ])
                            )
                        },
                        action: (snackbarId) => (
                            <div>
                                <button
                                    className="btn btn-ghost"
                                    onClick={() => closeSnackbar(snackbarId)}
                                >
                                    Dismiss
                                </button>
                            </div>
                        ),
                    }
                )
            }
        },
    })

    useAppointmentUpdatesSubscription({
        variables: { providerId },
        skip: !providerId,
        onData: async ({
            data,
        }: {
            data: any
        }) => {
            const appointment = data?.data?.appointmentUpdated?.appointment
            if (!isAppointmentInLagState(appointment)) {
                closeSnackbar(
                    `appointment:${appointment?.appointmentId}:waitForIntakeDelay`
                )
                closeSnackbar(
                    `appointment:${appointment?.appointmentId}:waitForDoctorDelay`
                )
            }
        },
    })

    return <></>
}
