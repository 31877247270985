import {
    compact,
    deburr,
    keys,
    isEqual,
    isNull,
    orderBy,
    isDate,
    isNumber,
    isString,
    sortBy,
    toString,
    toInteger,
    trim,
} from 'lodash'
import moment, { Duration } from 'moment'

/**
 * Function called to check and see if all fields of an object exist (not null, undefined, or trimmed empty string
 *
 * @param obj: a deal object with the entered values in it
 * @return {boolean}: a boolean representing whether or not all the information is there
 */
export function ifAllFieldsExist(obj: object): boolean {
    for (const key of keys(obj)) {
        const prop = obj[key]

        if (
            prop === null ||
            prop === undefined ||
            (isString(prop) && prop.trim().length === 0)
        ) {
            return false
        }
    }

    return true
}

// /**
//  * Function called to return all empty fields of an object as a list
//  *
//  * @param randomObj: an object with finite set of fields
//  * @param classNameEmpty: the css class if that field is empty (red border box)
//  * @param classNameFilled: the css class if that field is filled
//  * @return []: a list of fields that are empty in the object
//  */
// export function returnFieldsClasses(
//     randomObj: object,
//     classNameEmpty: string,
//     classNameFilled: string
// ): object {
//     const fieldObj = {}

//     for (const property in randomObj) {
//         if (randomObj?.[property] !== 'undefined') {
//             if (!randomObj[property] || randomObj[property] === '') {
//                 fieldObj[property] = classNameEmpty
//             } else {
//                 fieldObj[property] = classNameFilled
//             }
//         }
//     }
//     return fieldObj
// }

export function downloadCSV(filename: string, csv: string) {
    const blob = new Blob(['\ufeff', csv], { type: 'text/csv' })
    downloadFile(blob, filename)
}

//https://stackoverflow.com/a/16245768/2566094
export function base64toBlob(
    b64Data: string,
    contentType?: string,
    sliceSize?: number
): Blob {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    const byteCharacters = atob(b64Data)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

//https://stackoverflow.com/a/49820450/2566094
export function downloadFile(blob: Blob, filename: string): void {
    const a = document.createElement('a')
    document.body.appendChild(a)

    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()

    //don't set to 0ms because that can cause issues with ios safari (specifically when this is minified for some reason...)
    setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
    }, 5000)
}


export function formatDuration(minutes: number) {
    const hours = Math.floor(minutes/60)
    const reducedMinutes = minutes - hours % 60

    const hoursString = hours > 0 ? `${hours}h ` : ''
    const reducedMinutesString = reducedMinutes.toString().padStart(2, '0') + 'm'

    if (!(minutes >= 0)) return ''
    return  hoursString + reducedMinutesString
}

/**
 * NOTE: .asMinutes() returns the total minutes (including seconds) while .minutes() returns the minutes in the hour
 * Examples:
 *   moment.duration(330, 'seconds').asMinutes() -> 5.5
 *   moment.duration(330, 'seconds').minutes() -> 5
 *   moment.duration(325, 'minutes').asMinutes() -> 325
 *   moment.duration(325, 'minutes').minutes() -> 25
 */
export interface FormatDelayOptions {
    underOneText?: string
    hideMinutesAfterHour?: boolean
    leadingZeros?: boolean,
    durationType?: 'minutes' | 'seconds'
    timeFormat?: 'h:mm A' | string
}

export function formatDelay(
    inputDuration: Duration | number,
    options: FormatDelayOptions = {}
) {
    const {
        underOneText,
        hideMinutesAfterHour = false,
        leadingZeros = true,
        durationType = 'minutes',
    } = options
    if (isNull(inputDuration)) return ''
    
    const duration: Duration =
        typeof inputDuration === 'number'
            ? moment.duration(inputDuration, durationType)
            : inputDuration
    const durationInSeconds = duration ? Math.max(duration?.asSeconds(), 0) : null
    if (!duration?.isValid() || durationInSeconds === null) {
        return ''
    } else if (durationInSeconds <= 0) {
        return underOneText ? underOneText : 'None'
    } else if (durationInSeconds < 60) {
        return underOneText ?? `${duration.seconds().toString()}s`
    } else if (duration.asMinutes() < 60) {
        // duration.minutes() works because we make sure the total minutes is less than 60
        let minutes = duration.minutes().toString()
        if (leadingZeros) minutes = minutes.padStart(2, '0')
        return `${minutes}m`
    } else if (duration.asMinutes() >= 60) {
        const hours = Math.floor(duration.asHours())
        if (hideMinutesAfterHour === true) return `>${hours}h`
        let minutes = duration.minutes().toString()
        if (leadingZeros) minutes = minutes.padStart(2, '0')
        return `${hours}h ${minutes}m`
    }

    return ''
}



// export function formatLuxonDelay(
//     inputDuration: Duration | number,
//     options: FormatDelayOptions = {}
// ) {
//     const {
//         underOneText,
//         hideMinutesAfterHour = false,
//         leadingZeros = true,
//         durationType = 'minutes',
//     } = options
   
//     if (!inputDuration) return ''

//     const duration: Duration = typeof inputDuration === 'number'
//         ? Duration.fromMillis(inputDuration * 1000 * 60 * (durationType === 'minutes' ? 60 : 0))
//         : inputDuration

//     const durationInSeconds = duration ?  Math.max(0, duration.seconds * (durationType === 'minutes' ? 60 : 1)) : null
//     const durationInMinutes = duration.minutes >= 0 ? Math.floor(duration.minutes) : 0
//     const durationInMinutesReduced = duration.minutes % 60
//     const durationInHours = duration.hours >= 0 ? Math.floor(duration.hours) : 0
    
//     if (!durationInSeconds) {
//         return ''
//     } else if (durationInSeconds <= 0) {
//         return underOneText ? underOneText : 'None'
//     } else if (durationInSeconds < 60) {
//         return underOneText ?? `${durationInSeconds}s`
//     } else if (durationInMinutes < 60) {
//         // duration.minutes() works because we make sure the total minutes is less than 60
//         let minutes = durationInMinutes.toString()
//         if (leadingZeros) minutes = minutes.padStart(2, '0')
//         return `${minutes}m`
//     } else if (durationInMinutes >= 60) {
//         if (hideMinutesAfterHour === true) return `>${durationInHours}h`
//         let minutes = durationInMinutesReduced.toString()
//         if (leadingZeros) minutes = minutes.padStart(2, '0')
//         return `${durationInHours}h ${minutes}m`
//     }

//     return ''
// }

// export function noOp() {}

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function headerRowClasses(headerClass: string, rowClass: string) {
    return ({ index }: { index: number }) =>
        index === -1 ? headerClass : rowClass
}

export function simpleErrorToString(error, defaultMessage: string): string {
    if (error === null || error === undefined) {
        return defaultMessage
    }

    const prefix =
        isString(error.code) || isNumber(error.code) ? error.code : ''
    const message =
        isString(error.message) && error.message.length > 0
            ? error.message
            : defaultMessage

    return prefix + ': ' + message
}

// general table processing and sorting functions

export function processSearchText(searchText: string): string[] {
    return deburr(searchText).toLowerCase().trim().split(/\s+/)
}

export function hasRoughTextMatch(items: any[], searchValues: string[]) {
    if (searchValues.length === 0) {
        return true
    }

    return searchValues.every((searchValue) =>
        items.some((item) => toString(item).toLowerCase().includes(searchValue))
    )
}

export function areCompactedUniqueArraysEqual(
    array1: any,
    array2: any
): boolean {
    return isEqual(compact(sortBy(array1)), compact(sortBy(array2)))
}

export function cleanPhoneNumberInput(phoneNumber: string): string | null {
    phoneNumber = phoneNumber?.replace(' ', '')
    return phoneNumber === '' ? null : phoneNumber
}

export const genRanHex = (size) =>
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('')

export enum ListSortDirection {
    ASC,
    DESC,
}
