import { 
    AppointmentIcon,
    InsightsAdherenceIcon, 
    InsightsDeliveryIcon, 
    InsightsGrowthIcon,
    InsightsHomeIcon, 
    InsightsPerformanceIcon, 
    InsightsProductivityIcon, 
    InsightsProviderSummaryIcon,
    InsightsPatientFlowIcon,
    InsightsMonthlyIcon,
    AppointmentTypeIcon,
} from '@docpace/shared-react-icons'
import { IconType } from 'react-icons/lib'

const emptyParams: any = {}
interface PartialRouteOpts {
    icon?: IconType
    title?: string
} 


export class PartialRoute<T extends { [key: string]: string }> {
    constructor(public readonly path: string, public readonly opts?: PartialRouteOpts) {
        this.path = path
        this.opts = opts ?? {}
    }

    href(query: T = emptyParams): { pathname: string; query: T } {
        return {
            pathname: this.path,
            query,
        }
    }
}

const managerRoutes = Object.freeze({
    ForgotPassword: new PartialRoute('/auth/forgot'),
    ResetPassword: new PartialRoute('/auth/reset'),
    Signin: new PartialRoute('/auth'),
    AdminSignin: new PartialRoute('/auth/admin'),
    Signout: new PartialRoute('/api/auth/signoutManager'),
    Index: new PartialRoute('/', { icon: AppointmentIcon }),
    Configure: new PartialRoute('/configure', { icon: AppointmentIcon }),
    ConfigureManagerDashboard: new PartialRoute('/configure/dashboard', { icon: AppointmentIcon }),
    ConfigureRssSite: new PartialRoute('/configure/receptionstatus', { icon: AppointmentIcon }),
    ConfigureDrsSite: new PartialRoute('/configure/digitalreception', { icon: AppointmentIcon }),
    ConfigureAthenaAppointmentTypes: new PartialRoute('/configure/athena/appttypes', { icon: AppointmentIcon }),
    ConfigureAthenaDepartments: new PartialRoute('/configure/athena/departments', { icon: AppointmentIcon }),
    ConfigureAthenaProviders: new PartialRoute('/configure/athena/providers', { icon: AppointmentIcon }),
    ConfigureProviders: new PartialRoute('/configure/providers', { icon: AppointmentIcon }),
    ConfigureManagers: new PartialRoute('/configure/managers', { icon: AppointmentIcon }),
    ConfigurePatientNotifications: new PartialRoute('/configure/patientnotifications', { icon: AppointmentIcon }),
    ConfigureStopPatientNotifications: new PartialRoute('/configure/stoppatientnotifications', { icon: AppointmentIcon }),
    ManagerProviderByIdIndex: new PartialRoute<{ providerId: string }>(
        '/provider/[providerId]',
        { icon: AppointmentIcon } 
    ),
    // ManagerProviderByIdDayIndex: new PartialRoute<{ providerId: string }>('/provider/[providerId]/day', AppointmentIcon),
    // ManagerProviderByIdDayByDay: new PartialRoute<{ providerId: string }>('/provider/[providerId]/day/[day]', AppointmentIcon),
    // ManagerAppointmentTypeIndex: new PartialRoute('/manager/appointmentType', AppointmentTypeIcon),
    // ManagerAppointmentTypeById: new PartialRoute<{ appointmentTypeId: string }>('/manager/appointmentType/[appointmentTypeId]', AppointmentTypeIcon),
    ManagerAnalyticsIndex: new PartialRoute('/analytics', { icon: InsightsHomeIcon }),
    ManagerPerformanceAnalytics: new PartialRoute('/analytics/performance', { icon: InsightsPerformanceIcon }),
    ManagerGrowthAnalytics: new PartialRoute('/analytics/growth', { icon: InsightsGrowthIcon }),
    ManagerDeliveryAnalytics: new PartialRoute('/analytics/delivery', { icon: InsightsDeliveryIcon }),
    ManagerProductivityAnalytics: new PartialRoute('/analytics/productivity', { icon: InsightsProductivityIcon }),
    ManagerAdherenceAnalytics: new PartialRoute('/analytics/adherence', { icon: InsightsAdherenceIcon }),
    ManagerProviderSummaryAnalytics: new PartialRoute('/analytics/summary', { icon: InsightsProviderSummaryIcon }),
    ManagerMonthlyReportAnalytics: new PartialRoute('/analytics/monthly', { icon: InsightsPatientFlowIcon }),
    ManagerPatientFlowAnalytics: new PartialRoute('/analytics/patientflow', { icon: InsightsMonthlyIcon }),
    ManagerAnalyticsUpgrade: new PartialRoute('/analytics/upgrade', { icon: InsightsHomeIcon }),
})

export default managerRoutes
