'use client'
import { useAtom, WritableAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { throttle } from 'lodash'

export const closedDialogAtom = atomWithReset<null>(null)
export const ctxOpenDialogAtomAtom = atomWithReset<WritableAtom<any, [any], any>>(closedDialogAtom)

export const useDialogAtom = (_atom: WritableAtom<any, any[], any>)=>{
    const [ currentOpenDialogAtom, setCurrentOpenDialogAtom ] = useAtom(ctxOpenDialogAtomAtom ?? closedDialogAtom)
    const [ , setAtomValue ] = useAtom(_atom)
    const dialogAtomId = _atom?.toString()
 
    function doOpen(atomValue?: any){
        setAtomValue(atomValue)
        setCurrentOpenDialogAtom(_atom)
    }

    function closeDialog(){
        setCurrentOpenDialogAtom(closedDialogAtom)
    }

    // // the following two effects can be used to support tailwind
    // useEffect(()=>{
    //     const element: any = document.getElementById(dialogAtomId)
    //     isOpen ? element?.showModal() : element?.close()
    // }, [isOpen])
    
    // useEffect(()=>{
    //     const element: any = document.getElementById(dialogAtomId)
    //     element.addEventListener('close', (event)=>{
    //         onClose()
    //     })
    // }, [dialogAtomId])
    
    return { 
        doOpen, 
        isOpen: currentOpenDialogAtom?.toString() === _atom?.toString(),
        closeDialog: throttle(closeDialog, 750),
        dialogAtomId
    }

}