import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { useAtom } from 'jotai'

import { ManagerLayout } from '@docpace/manager-react-components/ManagerLayout'
import { apolloClient } from '@docpace/apps/manager-app/client'
import { useSessionSharing } from '@docpace/shared-react-hooks/useSessionSharing'
import { useManagerBase } from '@docpace/shared-react-hooks/useManagerBase'

import { ctxApiServerHostname, ctxDateObjectAtom, ctxDateObjectEffectsAtom } from '@docpace/shared-react-atoms'
import { serverHostname } from '../client'
import { useIsChangingRoute } from '@docpace/shared-react-hooks/useIsChangingRoute'
import { SharedContextDateTickerIntervalComponent } from '@docpace/shared-react-components'

import '@docpace/apps/manager-app/tailwind.css'
import '@docpace/shared-react-styles/shared/globals.css'
import '@docpace/shared-react-styles/shared/materialUI.css'
import '@docpace/shared-react-styles/shared/cards.css'
import '@docpace/shared-react-styles/shared/tables.css'
import '@docpace/shared-react-styles/shared/layout.css'
import 'react-datetime/css/react-datetime.css'

function ManagerApp({ Component, pageProps }: AppProps) {
    const { isChangingRoute } = useIsChangingRoute()
    const { isInitialized } = useSessionSharing()
    const [, setApiServerHostname ] = useAtom(ctxApiServerHostname)
    useEffect(()=>{setApiServerHostname(serverHostname)}, [])

    return (
        <ApolloProvider client={apolloClient}>
            <SnackbarProvider
                maxSnack={8}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Head>
                    <title>DOCPACE Manager</title>
                    <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                    />
                </Head>
                <main className="app"><>
                    <SharedContextDateTickerIntervalComponent />
                    { isInitialized && <ManagerLayout>
                        <Component {...pageProps} />
                    </ManagerLayout> }
                </></main>
            </SnackbarProvider>
        </ApolloProvider>
    )
}

export default ManagerApp
