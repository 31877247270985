import { useQuery, QueryHookOptions } from '@apollo/client'
import { useAtom } from 'jotai'

import { ManagerPracticeBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerPracticeBaseQueryResultFragment, ManagerPracticeBaseQueryParams } from '@docpace/manager-graphql'
import { ctxDateStartEndOfDayWithQueryAtom, ctxPracticeIdAtom } from '@docpace/shared-react-atoms'
import { useFormatCtxTzDateTime } from '@docpace/shared-react-hooks'
import { startOfDay } from 'date-fns'

export function useManagerPracticeBaseQuery(opts?: QueryHookOptions<ManagerPracticeBaseQueryResultFragment, Partial<ManagerPracticeBaseQueryParams>>){
    const queryResult
    = useQuery<ManagerPracticeBaseQueryResultFragment>(ManagerPracticeBaseQueryDocument, opts)
    return queryResult
}

interface UseContextManagerPracticeBaseQueryProps extends QueryHookOptions {
    dayStart?: Date
    skip?: boolean
}

export function useContextManagerPracticeBaseQuery(props?: UseContextManagerPracticeBaseQueryProps){
    const {
        dayStart = new Date(),
        skip,
        ...restProps
    } = props ?? {}
    const { formatDashDate } = useFormatCtxTzDateTime()
    const [ practiceId ] = useAtom(ctxPracticeIdAtom)
    const managerBaseQueryResult = useManagerPracticeBaseQuery({
        variables: {
            practiceId,
            // do not use the context atom here since this query should not be refetching when scrolling between days
            dayStart: formatDashDate(dayStart), 
        },
        skip: !practiceId || skip,
        ...restProps
    })
    const { data } = managerBaseQueryResult

    return {
        practice: data?.practice,
        departments: data?.departments?.nodes,
        providers: data?.providers?.nodes,
        ...managerBaseQueryResult
    }
}
