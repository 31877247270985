import React, { FC, useEffect, useState } from 'react'
import { LinkProps } from 'next/link'
import ReactHoverObserver from 'react-hover-observer'

import { 
    practiceOverviewTooltipText,
    workflowQualityTooltipText,
    growthTooltipText,
    appointmentDurationsTooltipText,
    workingHoursTooltipText,
    patientBehaviorTooltipText,
    templateViewTooltipText,
    patientFlowReportTooltipText,
    monthlyReportTooltipText,
    checkoutTableTooltipText,
} from './ManagerSidebar.constants'
import { useManagerSidebarStyles } from '@docpace/manager-react-components/ManagerLayout'
import clsx from 'clsx'
import Link from 'next/link'
import { useTheme } from '@material-ui/core/styles'
import { Tooltip } from '@docpace/shared-react-components/Tooltip'
import {
    List,
    Drawer,
    IconButton,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import { Divider } from '@docpace/shared-react-components/Divider'
import managerRoutes from '@docpace/shared-react-routes/managerRoutes'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PushpinPinnedIcon,
    PushpinUnpinnedIcon,
} from '@docpace/shared-react-icons'

export interface ManagerSidebarProps {
    practiceId?: string
    providerId?: string
    isOpen: boolean
    isPinned: boolean
    isHovering?: boolean
    showInsightsLinks: boolean
    insightsRequiresUpgrade: boolean
    showMonthlyReport: boolean
    showPatientFlowReport: boolean
    showCheckoutTable: boolean
    setIsSidebarPinned: (isSidebarPinned: boolean) => void
    setIsSidebarOpen: (isSidebarOpen: boolean) => void
}

export interface ManagerSidebarLinkProps {
    title: string
    Icon?: any
    linkProps?: LinkProps
    hideIcon?: boolean
    onHover?: (isHovering: boolean)=>void
    tooltipText?: string | any
    isHovering?: boolean
    disabled?: boolean
    dense?: boolean
    endIcon?: any
    onClick?: any
    id?: string
}

const MuiSidebarNavListIcon = React.memo(
    Object.assign(
        (props: any) => {
            const { linkProps, disabled, Icon } = props
            const iconComponent = (
                <div className={clsx( disabled ? 'cursor-not-allowed' : 'cursor-pointer', 'w-6 m-0 mr-2 text-gray-600 dark:text-white')}>
                    <Icon />
                </div>
            )
            return (linkProps && !disabled) ? (
                <Link {...linkProps}>
                    {iconComponent}
                </Link>
            ) : (
                iconComponent
            )
        },
        { displayName: 'MuiSidebarNavListIcon' }
    )
)

const MuiSidebarNavListItemText = React.memo(
    Object.assign(
        (props: any) => {
            const { title, classes, linkProps, disabled } = props
            const titleComponent = (
                <ListItemText
                    primary={title}
                    className={'mx-0 my-4 font-sm text-gray-600 dark:text-white'}
                />
            )
            return (linkProps && !disabled) ? (
                <Link {...linkProps}>
                    {titleComponent}
                </Link>
            ) : (
                <div className='overflow-hidden'>
                    <div style={{ marginLeft: 0 }} className=''>{ titleComponent }</div>
                </div>
            )
        },
        { displayName: 'MuiSidebarNavListItemText' }
    )
)

export const ManagerSidebarLink: FC<ManagerSidebarLinkProps> = (props) => {
    const { title, onClick, Icon, isHovering, onHover, tooltipText, hideIcon, disabled, dense, endIcon } = props
    useEffect(()=>{
        if(typeof isHovering === 'boolean' && onHover){
            onHover(isHovering)
        }
    }, [isHovering])
    const textProps = { ...props, disabled: undefined }
    const _ListItem = <ListItem
            key={title}
            onClick={onClick}
            className={clsx('h-10', disabled ? 'cursor-not-allowed' : 'cursor-pointer', hideIcon && 'm-0 p-0')}
            disabled={disabled}
            dense={dense}
        >
            {Icon && <MuiSidebarNavListIcon {...textProps} />}
            <MuiSidebarNavListItemText {...props} />
            {endIcon}
        </ListItem>

    return tooltipText ? <Tooltip title={tooltipText}>{ _ListItem }</Tooltip> : _ListItem
    // return _ListItem
}

const ManagerSidebarInsightsTeaserTooltip = ({ isOpen, insightsRequiresUpgrade, children })=>{
    return !isOpen && insightsRequiresUpgrade 
        ? <Tooltip placement='right' title={<div
            className={clsx(
                // 'bg-white bg-center border border-solid rounded-lg',
                'flex flex-col py-2 space-y-1 items-center cursor-pointer',
                // 'transition-opacity duration-500 ease-in-out', 
            )} 
            // style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
            <div className='text-center font-semibold'>DOCPACE Insights</div>
            <div>(Premium Feature)</div>
            <div>Click any icon to learn more</div>
        </div>}><div>{ children }</div></Tooltip>
        : <div>{ children }</div>
}

export const ManagerSidebar: FC<ManagerSidebarProps> = (props) => {
    const {
        practiceId,
        providerId,
        isOpen,
        isPinned,
        isHovering,
        setIsSidebarOpen,
        setIsSidebarPinned,
        showInsightsLinks,
        insightsRequiresUpgrade,
        showMonthlyReport,
        showPatientFlowReport,
        showCheckoutTable
    } = props
    const classes = useManagerSidebarStyles()
    const theme = useTheme()
    const MenuTrayIcon = () => {
        const isLtr = theme.direction === 'ltr'
        const isChevronLeft = !(isLtr && isOpen) && (isLtr || isOpen)
        return (
            <IconButton
                onClick={() => setIsSidebarOpen(!isOpen)}
                className={classes.closeIcon}
            >
                {isChevronLeft ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        )
    }
    const PushpinIcon = () => {
        const style = { fontSize: '0.9rem', right: 5 }
        return (
            <IconButton className={classes.closeIcon}>
                {isPinned ? (
                    <PushpinPinnedIcon style={style} />
                ) : (
                    <PushpinUnpinnedIcon style={style} />
                )}
            </IconButton>
        )
    }

    useEffect(() => {
        !isPinned && setIsSidebarOpen(!!isHovering)
    }, [isHovering, isPinned])
    
    if (!practiceId) return <></>
    return (
        <Drawer
            id={'manager-sidebar'}
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                }),
            }}
        >
            <List style={{ paddingTop: 0 }}>
                <div className={classes.toolbar}>
                    <MenuTrayIcon />
                </div>
                
                { showCheckoutTable && <>
                    <ManagerSidebarLink
                        title="Appointments"
                        Icon={managerRoutes.Index.opts.icon}
                        tooltipText={checkoutTableTooltipText}
                        linkProps={{
                            href: providerId
                                ? managerRoutes.ManagerProviderByIdIndex.href({
                                    providerId,
                                })
                                : managerRoutes.Index.href(),
                        }}
                    />
                    <Divider/>
                </> }
                {/* <ManagerSidebarLink title='Appointment Types'
                    linkProps={{ href: managerRoutes.ManagerAppointmentTypeIndex.href() }} 
                    Icon={managerRoutes.ManagerAppointmentTypeIndex.opts.icon} 
                /> */}
                {/* <div className='divider'/> */}
                {/* <ManagerSidebarLink
                    title="Reports"
                    linkProps={{
                        href: '#',
                    }}
                    Icon={managerRoutes.ManagerAnalyticsIndex.opts.icon}
                /> */}
                {/* <ManagerSidebarLink
                    title="Practice Overview"
                    tooltipText={null}
                    disabled={false}
                    linkProps={{
                        href: managerRoutes.ManagerAnalyticsIndex.href(),
                    }}
                    Icon={managerRoutes.ManagerAnalyticsIndex.opts.icon}
                /> */}
                { showInsightsLinks && <ManagerSidebarInsightsTeaserTooltip isOpen={isOpen} insightsRequiresUpgrade={insightsRequiresUpgrade}>
                    { insightsRequiresUpgrade && <Link 
                        href={managerRoutes.ManagerAnalyticsUpgrade.href()}
                        className={clsx(
                            'absolute bg-white bg-center border border-solid rounded-lg',
                            'flex flex-col p-2 items-center z-10 cursor-pointer',
                            'transition-opacity duration-500 ease-in-out', 
                            !isOpen ? 'opacity-0' : 'opacity-100'
                        )} 
                        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <div className='text-center font-semibold'>DOCPACE Insights</div>
                        <div>(Premium Feature)</div>
                        <div>Click <span className='underline'>here</span> to learn more</div>
                    </Link> }
                    <div className={clsx(insightsRequiresUpgrade && 'opacity-50')}>
                        <ReactHoverObserver>
                            <ManagerSidebarLink
                                title="Practice Overview"
                                tooltipText={insightsRequiresUpgrade ? null : practiceOverviewTooltipText}
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerAnalyticsIndex.href(),
                                }}
                                Icon={managerRoutes.ManagerAnalyticsIndex.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Workflow Quality"
                                tooltipText={insightsRequiresUpgrade ? null : workflowQualityTooltipText }
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerPerformanceAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerPerformanceAnalytics.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Growth Analytics"
                                tooltipText={insightsRequiresUpgrade ? null : growthTooltipText }
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerGrowthAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerGrowthAnalytics.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Appt Durations"
                                tooltipText={insightsRequiresUpgrade ? null : appointmentDurationsTooltipText }
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerDeliveryAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerDeliveryAnalytics.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Working Hours"
                                tooltipText={insightsRequiresUpgrade ? null : workingHoursTooltipText }
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerProductivityAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerProductivityAnalytics.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Patient Behavior"
                                tooltipText={insightsRequiresUpgrade ? null : patientBehaviorTooltipText }
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerAdherenceAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerAdherenceAnalytics.opts.icon}
                            />
                            <ManagerSidebarLink
                                title="Template View"
                                tooltipText={insightsRequiresUpgrade ? null : templateViewTooltipText}
                                linkProps={{
                                    href: insightsRequiresUpgrade ? managerRoutes.ManagerAnalyticsUpgrade.href() 
                                        : managerRoutes.ManagerProviderSummaryAnalytics.href(),
                                }}
                                Icon={managerRoutes.ManagerProviderSummaryAnalytics.opts.icon}
                            />
                        </ReactHoverObserver>
                    </div>
                </ManagerSidebarInsightsTeaserTooltip>}
                <Divider />
                { showMonthlyReport && <ManagerSidebarLink
                    title="Monthly Report"
                    tooltipText={monthlyReportTooltipText}
                    linkProps={{
                        href: managerRoutes.ManagerMonthlyReportAnalytics.href(),
                    }}
                    Icon={managerRoutes.ManagerMonthlyReportAnalytics.opts.icon}
                /> }
                { showPatientFlowReport && 
                    <ManagerSidebarLink
                        title="Patient Flow"
                        tooltipText={patientFlowReportTooltipText}
                        linkProps={{
                            href: managerRoutes.ManagerPatientFlowAnalytics.href(),
                        }}
                        Icon={managerRoutes.ManagerPatientFlowAnalytics.opts.icon}
                    />
                }
            </List>
            <List
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'flex-end',
                    right: 2,
                }}
            >
                <ManagerSidebarLink
                    title={`${isPinned ? 'Unpin' : 'Pin'} Sidebar`}
                    id='sidebar-pushpin-button'
                    Icon={PushpinIcon}
                    onClick={() => setIsSidebarPinned(!isPinned)}
                />
            </List>
        </Drawer>
    )
}
