export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  JwtToken: any;
  /** The exact time of day, does not include the date. May or may not have a timezone offset. */
  Time: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export enum AppointmentSortDirection {
  Scheduled = 'Scheduled',
  Docpace = 'Docpace'
}

export enum ManagerNotificationMethod {
  Flash = 'Flash',
  Snackbar = 'Snackbar'
}


export enum CalculatedAppointmentStatus {
  Scheduled = 'Scheduled',
  CheckingIn = 'CheckingIn',
  CheckedIn = 'CheckedIn',
  WaitForIntake = 'WaitForIntake',
  Intake = 'Intake',
  WaitForDoctor = 'WaitForDoctor',
  Exam = 'Exam',
  WaitForCheckout = 'WaitForCheckout',
  CheckingOut = 'CheckingOut',
  CheckedOut = 'CheckedOut',
  Canceled = 'Canceled',
  LeftWithoutBeingSeen = 'LeftWithoutBeingSeen',
  NoShow = 'NoShow',
  Rescheduled = 'Rescheduled',
  Arrived = 'Arrived',
  Done = 'Done',
  Unknown = 'Unknown'
}